import ReactDOM from "react-dom/client";

import "./src/global.css";
require("@stoplight/elements-dev-portal/styles.min.css");

// https://github.com/gatsbyjs/gatsby/discussions/36232
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
